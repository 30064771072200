import JsonRequest from '../../request/json_request'

const user = JSON.parse(localStorage.getItem("sign-in-user") || "null")
const initialState = {
    // socket: null,
    // 当前用户
    // user: user,
    // 导航栏当前激活的key
    // navActiveKey: null,
    // 通知频道实例
    // notificationChannel: null,
    // json请求客户端
    jsonRequest: JsonRequest.tokenClient(user?.token)
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // 菜单切换
        case "MenuSetActive":
            return { ...state, navActiveKey: action.key }
        // 服务器的socket连接
        case "GlobalSocket":
            return { ...state, socket: action.socket }
        // 登录
        case "Session:SignedIn":
            return { ...state, user: action.user, jsonRequest: JsonRequest.tokenClient(action.user.token) }
        // 退出
        case "Session:LogOut":
            return { ...state, user: null, jsonRequest: new JsonRequest() }
        // 全局通知频道连接
        case "NotificationChannel:Connect":
            return { ...state, notificationChannel: action.channel }
        // 全局通知频道离开
        case "NotificationChannel:Disconnect":
            !!state.notificationChannel && state.notificationChannel.leave()
            return { ...state, notificationChannel: undefined }
        default:
            return state;
    }
}
export default reducer