import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import { connect } from "react-redux"
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'

import Article from './Article'
import TongueTips from './help/TongueTips'

dayjs.locale('zh-cn');
dayjs.extend(relativeTime)
dayjs.extend(weekday)
dayjs.extend(localeData)

const mapStateToProps = ({ global }) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

function Home(props) {
    return <Router>
        <div className="App">
            <main role="main" className="container">
                <Switch>
                    <Route exact path="/diagnose/tongue/tips" component={TongueTips} />
                    <Route path="/:column_title/:article_title" component={Article} />
                </Switch>
            </main>
        </div>
    </Router>
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)