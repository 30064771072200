import edjsHTML from "editorjs-html"

const rawParser = (block) => {
    console.log(block)
    return block.data.html;
}

const imagParser = (block) => {

    let borderStyle = ""
    let backgroundColor = ""
    let style = "background-size: contain;"

    if (block.data.withBorder)
        borderStyle = 'border: 1px solid #eee; border-radius: 5px'

    if (block.data.withBackground)
        backgroundColor = 'background-color: #eee'

    if (block.data.stretched)
        style = 'background-size: 100%;'

    return `<div class="image-content" style=" ${backgroundColor};">
        <div class="image-content-bg" style="background-image: url(${block.data.file.url}); ${style}; ${borderStyle}">
            <img alt="${block.data.caption}" style="${block.data.stretched ? "width='100%'" : ''};" src="${block.data.file.url}"/>
        </div>
    </div>`
}

const edjsParser = edjsHTML({ raw: rawParser, image: imagParser });


export default edjsParser;