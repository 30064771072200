import { Provider } from 'react-redux';
import Home from './Home'
import reducer from './store'

import { createStore } from "redux"
const store = createStore(reducer)

function App() {
  return <Provider store={store}><Home /></Provider >
}

export default App;
