import React from "react";
import TimeAgo from "react-timeago"
import frenchStrings from './local_time_ago_zh'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
const formatter = buildFormatter(frenchStrings)

class LocalTimeAgo extends React.Component {
    render() {
        return <TimeAgo date={this.props.date} formatter={formatter} maxPeriod={60} {...this.props} />;
    }
}

export default LocalTimeAgo;