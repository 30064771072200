export default class Tools {
    static isWeChat() {
        let userAgentInfo = navigator.userAgent.toLowerCase();
        if (userAgentInfo.match(/MicroMessenger/i) === 'micromessenger') {
            document.body.addEventListener('touchmove', function (event) {
                event.preventDefault();
            }, false);
            return true;
        } else {
            return false;
        }
    }

    static isIos() {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
         //ios终端
        return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    }
}