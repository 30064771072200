import "./TongueTips.scss"

export default function TongueTips(props) {
    return <div className="page-tongue-tips">
        <section className="group">
            <div className="title">姿势</div>
            <div className="text">
                正坐或自然站立，尽量张口，舌头自然舒展伸出口外，微抬头使舌体充分暴露，以照到舌根为佳。避免舌体紧张、过分用力或伸舌时间过长。
            </div>
        </section>

        <section className="group">
            <div className="title">光线</div>
            <div className="text">
                光线亮度正常，过亮或过暗都会影响检测，尽量以白天充足而柔和的自然光线为佳，再夜间或暗处用日光为好。光线要直接照射到舌面，避免面对有色门窗。
            </div>
        </section>
        <section className="group">
            <div className="title">饮食</div>
            <div className="text">
                牛奶等食物或药物会使舌苔染色，花生、瓜子等坚果类食用过程中会使厚苔变薄，过冷或过热的饮食及刺激性食物可改变舌色，拍照前尽量不要食用上述食物。
            </div>
        </section>
        <section className="group">
            <div className="title">时间</div>

            <div className="text">
                不同的时间或季节舌象往往会有变化，最佳拍照时间为晨起后，未洗漱及进食前，进食后尽量漱口并等待半小时再拍照。
            </div>
        </section>
    </div>
}